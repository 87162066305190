
import { RequestPaging } from "@/utils/util";
import { Component, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import { apiModlestlylist, apiAXsynmodlestly } from "@/api/model";
import PopoverInput from "@/components/popover-input.vue";

@Component({
    components: {
        LsDialog,
        LsPagination,
        PopoverInput,
    },
})
export default class ModelType extends Vue {
    apiModlestlylist = apiModlestlylist;
    pager: RequestPaging = new RequestPaging();
    list: any = [];
    searchObj: any = {
        id: "",
        name: "",
    };
    getList(): void {
        this.pager.request({
            callback: apiModlestlylist,
            params: {
                ...this.searchObj,
            },
        });
    }
    handleAsyncData() {
        apiAXsynmodlestly({}).then((res) => {
            this.getList();
        });
    }
    created() {
        this.getList();
    }
}
